import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Chevron from "@svgs/chevron.svg";

const ListItemComponent = ({ text, href }) => (
  <li className="h-auto text-sm text-gray-700 hover:text-primary">
    <Link to={href} className="flex items-center pr-6 py-3 w-full h-full">
      <Chevron className="w-3 h-3 text-gray-300" />
      <span className="w-24">{text}</span>
    </Link>
  </li>

);
const ListTitle = ( {text} ) => (
  <div className="mt-8 mb-2">
      <span className="">{text}</span>
      <span className="block h-0.5 bg-primary bg-opacity-75 mt-1"></span>
  </div>
);

const Footer =  () => (
<footer className="z-0 relative w-full">
  <div className="w-full py-16 bg-gray-100 border-t-4 border-gray-300 lg:px-auto">
    <nav className="flex flex-wrap justify-around mx-auto max-w-lg pt-8 px-4 lg:max-w-2xl lg:justify-between">
      <Link to="/" className="flex-none w-full pb-4">
        <StaticImage src="../images/logo.png" alt="Logo" width={150} placeholder="none" />
      </Link>
      <ul className="flex-none w-full">
        <ListItemComponent text="お問い合わせ" href="/contact" />
      </ul>
      <ul>
          <ListTitle text="事業内容" />
          <ListItemComponent text="事業内容" href="/business" />
          <ListItemComponent text="取扱い商品" href="/products" />
          <ListItemComponent text="事業所一覧" href="/branch" />
      </ul>
      <ul>
          <ListTitle text="会社情報"/>
          <ListItemComponent text="会社概要" href="/company" />
          <ListItemComponent text="当社の沿革" href="/history" />
          <ListItemComponent text="本社ビル施設" href="/office" />
          <ListItemComponent text="プライバシーポリシー" href="/privacy" />
          <ListItemComponent text="お知らせ" href="/news" />
      </ul>
      <ul>
          <ListTitle text="採用関連"/>
          <ListItemComponent text="採用情報" href="/recruit" />
      </ul>
      </nav>
  </div>
  <div className="flex items-center justify-center w-full h-10 bg-primary">
    <p className="text-xs text-white ">
      Copyright (C) kihara Corporation. All Rights Reserved.
    </p>
  </div>
</footer>
)

export default Footer;