import React from "react"
import Header from "@components/header"
import Footer from "@components/footer"
import "@fontsource/noto-sans-jp";
import { isIE } from 'react-device-detect';

const Layout = ({ children }) => {
  if (isIE) {
    return (
      <> {/* <React.Fragment>の省略形 */}
        <Header />
        <p className="mt-24 ml-8 mr-8"> IE（Internet Explorer）はサポート対象外のため、 Chrome/Firefox/Edgeをダウンロードしてください。 </p>
      </>
    )
  }
  return (
    <> {/* <React.Fragment>の省略形 */}
      <Header />
      {children}
      <Footer />
    </>
  )
}

export default Layout;