import React, { useState } from 'react';
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Chevron from "@svgs/chevron.svg";
import Menu from "@svgs/menu.svg";
import Close from "@svgs/close.svg";
import Mail from "@svgs/mail.svg";

//List Item in Navi
const ListItemComponent = ({ text, href }) => (
  <li className="
          relative h-16
          border-gray-400 border-t
          lg:w-28 lg:mt-2
          lg:border-none">
    <Link to={href}
      className="
              absolute top-0 left-0
              w-full h-full
              flex items-center
              lg:hover:bg-primary lg:hover:bg-opacity-75 lg:hover:text-white lg:hover:border-black
              lg:transition-all lg:duration-500">
      <span className="ml-6 lg:mx-auto">{text}</span>
      <Chevron
        className="
                  w-3 h-3 ml-auto
                  mr-5
                  fill-current text-gray-300
                  lg:hidden"
      />
    </Link>
  </li>
);
const ButtonItemComponent = ({ text, href }) => (
  <li className="
  relative h-16
  border-gray-400 border-t
  flex justify-center items-center
  lg:w-28 lg:mt-2
  lg:border-none">
    <Link to={href}
      className="
              mt-4 lg:mt-0
              lg:hover:bg-primary lg:hover:bg-opacity-75 lg:hover:text-white lg:hover:border-black
              lg:transition-all lg:duration-500">
      <button className="bg-secondary text-primary border-secondary font-bold py-3 px-3 inline-flex items-center shadow-lg rounded-md">
        <Mail
          className="
          w-4
          my-1 mx-2
          lg:hidden"
        />
        <span className="font-bold">{text}</span>
        <Chevron
          className="
          w-3
          my-1 ml-4 mr-2
          fill-current
          lg:hidden"
        />      </button>
    </Link>
  </li>
);


const Header = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <header className={
      isOpen
        ? 'z-10 fixed top-0 w-full h-full bg-gray-500 bg-opacity-50 lg:h-20 lg:bg-white lg:bg-opacity-100 lg:shadow'
        : 'z-10 fixed top-0 w-full h-16 bg-white shadow lg:h-20'}>
      <Link to="/" className="absolute top-5 left-6 h-8 z-10 lg:left-8 lg:top-7">
        <StaticImage src="../images/logo_s.png" alt="Logo" height={28} placeholder="none" />
      </Link>
      <nav className={
        isOpen
          ? 'fixed top-0 -left-52 transform transition-transform duration-300 pt-16 w-52 h-full bg-white lg:pt-0 lg:h-16 lg:w-full translate-x-52'
          : 'fixed top-0 -left-52 transform transition-transform duration-300 pt-16 w-52 h-full bg-white lg:pt-0 lg:h-16 lg:w-full translate-x-0 lg:left-0'}>
        <ul className="text-left text-sm tracking-wider lg:flex lg:items-center lg:justify-end lg:mr-8">
          <ListItemComponent text="トップページ" href="/" />
          <ListItemComponent text="事業内容" href="/business" />
          <ListItemComponent text="会社概要" href="/company" />
          <ListItemComponent text="採用情報" href="/recruit" />
          <ListItemComponent text="事業所一覧" href="/branch" />
          <ListItemComponent text="お問合わせ" href="/contact" />
        </ul>
      </nav>
      <div className="header-menu-button-position lg:hidden">
        <button className="flex items-center px-3 py-2 bg-white rounded text-primary"
          onClick={() => setOpen(!isOpen)}>
          {
            isOpen
              ? <Close className="h-6 w-6" />
              : <Menu className="h-7 w-7" />
          }
        </button>
      </div>
    </header>
  )
}

export default Header;