import React from "react"

export const H1 = ({ text }) => (
  <div className="mt-16 mb-12 w-full h-20 bg-secondary flex items-center justify-center lg:mt-20 lg:h-28">
    <h1 className="text-2xl text-primary">
      {text}
    </h1>
  </div>
)

export const H2 = ({ text }) => (
  <div className="mt-12 mb-6">
    <h2 className="text-xl ">
      {text}
    </h2>
  </div>

)

export const H3 = ({ text }) => (
  <div className="mt-10 mb-2 flex items-center lg:mb-6">
    <span className="mr-3 block h-6 w-2 bg-primary lg:mr-4" />
    <h3 className="font-bold lg:text-xl">
      {text}
    </h3>
  </div>

)

export const H4 = ({ text }) => (
  <div className="mt-4 ml-4">
    <h4 className="font-bold">
      {text}
    </h4>
  </div>
)